import { ref } from '@vue/composition-api'

export default function coupon() {
  const couponForm = ref({
    for_only_one_time: 0,
    for_new_user: 0,
  })

  const viewData = ref({})

  const typeOptions = [
    { text: 'Cash in', value: 1 },
    { text: 'Transaction', value: 2 },
    { text: 'Download', value: 3 },
  ]

  const transactionTypeOptions = [
    { text: 'Please select an option', value: '' },
    { text: 'Order', value: 1 },
    { text: 'Deposit', value: 2 },
    { text: 'Withdrwal', value: 3 },
  ]

  const amountpercentage = [
    { text: 'Amount', value: 'amount' },
    { text: 'Percentage', value: 'percentage' },
  ]

  const resolveCashBackType = role => {
    if (role === 'percentage') return '%'
    if (role === 'amount') return 'amount'

    return 'N/A'
  }
  const resolveActiveUser = role => {
    if (role === true) return 'Active'

    return 'Not Active'
  }

  return {
    couponForm,
    typeOptions,
    amountpercentage,
    transactionTypeOptions,
    viewData,
    resolveCashBackType,
    resolveActiveUser,
  }
}
