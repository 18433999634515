<template>
  <b-row>
    <b-col
      lg="12"
    >
      <b-card>
        <validation-observer
          ref="infoRules"
          v-slot="{ invalid }"
          tag="form"
        >
          <b-row>
            <b-col md="6">
              <!-- Name -->
              <validation-provider
                #default="validationContext"
                name="code"
                rules="required"
              >
                <b-form-group
                  label="Coupon Code"
                  label-for="code"
                >
                  <b-form-input
                    id="code"
                    v-model="couponForm.code"

                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Coupon Code"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="validationContext"
                rules="required"
                name="Description"
              >
                <b-form-group
                  label="Description"
                  label-for="description"
                >
                  <b-form-textarea
                    id="description"
                    v-model="couponForm.description"
                    trim
                    type="text"
                    placeholder="Description"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Offer Type"
                rules="required"
              >
                <b-form-group
                  v-slot="{ ariaDescribedby }"
                  label="Offer Type"
                >
                  <b-form-radio
                    v-model="couponForm.type"
                    :aria-describedby="ariaDescribedby"
                    name="type"
                    value="1"
                  >
                    percentage
                  </b-form-radio>
                  <b-form-radio
                    v-model="couponForm.type"
                    :aria-describedby="ariaDescribedby"
                    name="type"
                    value="2"
                  >
                    Amount
                  </b-form-radio>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="couponForm.type==1"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                rules="required"
                name="offer"
              >
                <b-form-group
                  label="Offer (in %)"
                  label-for="QL"
                >
                  <b-form-input
                    id="QL"
                    v-model.number="couponForm.offer"
                    trim
                    type="number"
                    placeholder="Offer (in %)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="couponForm.type==2"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                rules="required"
                name="offer"
              >
                <b-form-group
                  label="Offer (in Numbers)"
                  label-for="QL"
                >
                  <b-form-input
                    id="QL"
                    v-model.number="couponForm.offer"
                    trim
                    type="number"
                    placeholder="Offer (in Numbers)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Quantity "
                rules="required"
              >
                <b-form-group
                  label="Quantity "
                  label-for="UL"
                >
                  <b-form-input
                    id="UL"
                    v-model.number="couponForm.max_usage_num"
                    :state="getValidationState(validationContext)"
                    trim
                    type="number"
                    placeholder="Quantity"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Max Usage Number for one user"
                rules="required"
              >
                <b-form-group
                  label="Max Usage Number for one user"
                  label-for="UL"
                >
                  <b-form-input
                    id="UL"
                    v-model.number="couponForm.max_usage_num_for_one_user"
                    :state="getValidationState(validationContext)"
                    trim
                    type="number"
                    placeholder="Max Usage Number for one user"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Min Order "
                rules="required"
              >
                <b-form-group
                  label="Min Order "
                  label-for="UL"
                >
                  <b-form-input
                    id="UL"
                    v-model.number="couponForm.min_order"
                    :state="getValidationState(validationContext)"
                    trim
                    type="number"
                    placeholder="100"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Max Discount "
                rules="required"
              >
                <b-form-group
                  label="Max Discount"
                  label-for="UL"
                >
                  <b-form-input
                    id="UL"
                    v-model.number="couponForm.max_discount "
                    :state="getValidationState(validationContext)"
                    trim
                    type="number"
                    placeholder="500"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="product_gifts"
              >
                <b-form-group
                  label="Campaign (id-product-gift)"
                  label-for="product_gifts"
                >
                  <v-select
                    v-model="couponForm.product_gift_id"
                    label="product_name"
                    :options="product_gifts"
                    :filterable="false"
                    :reduce="product_gifts => product_gifts.id"
                    @search="searchproduct_gifts"
                  >
                    <template
                      slot="option"
                      slot-scope="option"
                    >
                      {{ option.id }} - {{ option.product_name }} - {{ option.gift_name }}
                    </template>
                    <template
                      slot="selected-option"
                      slot-scope="option"
                    >
                      {{ option.id }} - {{ option.product_name }} - {{ option.gift_name }}
                    </template>
                  </v-select>

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Expired at"
                rules="required"
              >
                <b-form-group
                  label="Expired at"
                >
                  <b-form-datepicker
                    id="example-datepicker"
                    v-model="couponForm.original_expire_at"
                    :state="getValidationState(validationContext)"
                    class="mb-1"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              v-if="$route.params.id "
              md="6"
            >
              <b-form-group
                label="Coupon Activation"
                label-for="active"
              >
                <b-form-checkbox
                  id="active"
                  v-model="couponForm.is_active "
                  value="1"
                  unchecked-value="0"
                  class="custom-control-secondary"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <b-col
              v-if="errorMsg"
              cols="12"
            >
              <p class="text-danger">
                {{ errorMsg }}
              </p>
            </b-col>
            <!-- Server Errors -->
            <b-col
              v-if="Object.values(errors).length > 0"
              cols="12"
            >
              <p
                v-for="(error, key, index) in errors"
                :key="index"
                class="text-danger small"
              >
                {{ error.toString() }}
              </p>
            </b-col>
            <b-col
              cols="12"
              class="mt-50"
            >
              <b-button
                v-if="!loader"
                variant="primary"
                class="mr-1"
                :disabled="invalid || Object.values(errors).length > 0"
                @click.prevent="addCoupon()"
              >
                Save
              </b-button>
              <b-button
                v-if="loader"
                variant="primary"
                disabled
                class="mr-1"
              >
                <b-spinner
                  small
                  type="grow"
                />
                Loading...
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import { required, email } from '@validations'
import axios from 'axios'
import vSelect from 'vue-select'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToggleButton from 'vue-js-toggle-button'
import _ from 'lodash'
import handleFormData from '@/global-compositions/handleFormData'
import coupon from '../promoCode'
// import store from '@/store'

Vue.use(ToggleButton)

export default {
  components: { vSelect },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      email,
      merchants: [],
      categories: [],
      product_gifts: [],
      test: '',
      value: '',
      errorMsg: '',
      loader: false,
      validationErrors: {},
      merchant_id: '',
      is_active: 0,
      errors: {},
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    pinToggleIcon() {
      return this.pinFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'couponForm.merchant_id': function (value) {
      if (value) {
        if (this.validationErrors.merchant_id) {
          this.validationErrors.merchant_id = ''
        }
      }
    },
  },
  setup() {
    const {
      couponForm, typeOptions, amountpercentage, transactionTypeOptions,
    } = coupon()
    const {
      getValidationState,
    } = formValidation()
    const { formData, setFormData } = handleFormData()

    return {
      getValidationState,
      couponForm,
      typeOptions,
      amountpercentage,
      transactionTypeOptions,
      formData,
      setFormData,
    }
  },
  mounted() {
    this.showCoupon()
    this.getproduct_gifts()
  },
  methods: {
    onChange(e) {
      // eslint-disable-next-line prefer-destructuring
      this.couponForm.photo = e.target.files[0]
      // console.log(e)
      const input = e.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = er => {
          this.preview = er.target.result
        }
        // eslint-disable-next-line prefer-destructuring
        this.image = input.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },

    async searchproduct_gifts(searchQuery) {
      await axios.get(`admin/product_gifts?search=${searchQuery}`).then(res => {
        if (res.status === 200) {
          this.product_gifts = res.data?.data.product_gifts
        }
      })
    },
    async getproduct_gifts() {
      await axios.get('admin/product_gifts').then(res => {
        if (res.status === 200) {
          this.product_gifts = res.data?.data.products
        }
      })
    },
    selectCategory(value) {
      this.couponForm.category_id = this.categories.find(owner => owner.id === value.id).id
    },
    onSearch(search, loading) {
      if (search.length > 2) {
        loading(true)
        this.search(loading, search)
      }
    },
    // eslint-disable-next-line func-names
    search: _.debounce(function (loading, search) {
      axios.get(`get-merchants?search=${search}`).then(res => {
        if (search.length >= 2) {
          this.merchants = res.data.data
        }
        loading(false)
      })
    }, 500),
    showCoupon() {
      if (this.$route.params.id) {
        axios.get(`admin/coupons/${this.$route.params.id}`).then(res => {
          this.couponForm = res.data.data.coupon
          this.couponForm.code = this.couponForm.code.substring(1)
        })
      } else {
        return false
      }
      return true
    },
    addCoupon() {
      if (this.$route.params.id) {
        this.loader = true

        this.couponForm.expired_at = this.couponForm.original_expire_at
        this.setFormData(this.couponForm)
        this.formData.append('_method', 'Post')
        axios.post(`admin/coupons/${this.$route.params.id}`, this.formData).then(res => {
          if (res.status === 200) {
            this.$router.push({ name: 'promoCode' })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Updated Successfully',
                icon: 'BellIcon',
                variant: 'success',
              },
            })
          }
        }).catch(error => {
          if (error.response.status === 500) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ooops!!! An Unexpected Internal server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          } else if (error.response.status === 422) {
            if (error.response.data.errors !== undefined) {
              this.errorMsg = error.response.data.errors
            } else {
              this.errorMsg = error.response.data.message
            }
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        this.loader = true
        this.couponForm.expired_at = this.couponForm.original_expire_at
        this.setFormData(this.couponForm)
        axios.post('admin/coupons', this.formData).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$router.push({ name: 'promoCode' })
            this.$toasted.show('Created Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status === 500) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ooops!!! An Unexpected Internal server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          } else if (error.response.status === 422) {
            if (error.response.data.errors !== undefined) {
              this.errorMsg = error.response.data.errors
            } else {
              this.errorMsg = error.response.data.message
            }
          }
        }).finally(() => {
          this.loader = false
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.order-type label.btn.btn-primary.active {
    background-color: #0074c7 !important;
}
</style>
